<template>
  <div class="row g-5 g-xxl-8 mx-0 bank-follow-table">
    <div class="card mb-5 mb-xl-10 py-2">
      <div class="card-header px-0">
        <div class="card-title w-100 d-flex justify-content-between m-0">
          <h3 class="fw-bolder m-0">
            {{ $t("Followed Countries") }}
            <span class="count-label ms-2">{{ userDetails.followed_countries.length }}</span>
          </h3>

<!--          <div class="search-countries">-->
<!--            <el-select-->
<!--                v-model="value"-->
<!--                multiple-->
<!--                filterable-->
<!--                default-first-option-->
<!--                :reserve-keyword="false"-->
<!--                :placeholder="$t('Search Countries')"-->
<!--                collapse-tags-->
<!--            >-->
<!--              <el-option-->
<!--                  v-for="(item, index) in countries"-->
<!--                  :key="index"-->
<!--                  :label="item"-->
<!--                  :value="item"-->
<!--              />-->
<!--            </el-select>-->
<!--          </div>-->
        </div>
      </div>

      <div class="table-wrapper responsive-table">
        <el-table
          :data="userDetails.bank_countries"
          :show-header="false"
          style="width: 100%">
<!--          <el-table-column prop="icon_path" class-name="bank-follow-table__icon_path" width="70">-->
<!--            <template #default="scope">-->
<!--              <img :src="scope.row.icon_path" alt="">-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column prop="name" class-name="bank-follow-table__name">
            <template #default="scope">
              <h1>{{ scope.row.name }}</h1>
            </template>
          </el-table-column>
          <el-table-column prop="num_1" class-name="bank-follow-table__num_1" width="80">
            <template #default="scope">
              <div class="follow-status" :class="getStatusClass(scope.row.score)">
                {{ scope.row.score }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="num_2" class-name="bank-follow-table__num_2" width="80">
            <template #default="scope">
              <div class="follow-status">
                {{ scope.row.importance }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="followed" class-name="bank-follow-table__followed" width="250">
            <template  #default="scope">
              <div class="pe-5">
                <FollowingButton
                  :title="$t('Follow Country')"
                  type="outline"
                  :loading="loading[scope.row.name]"
                  :status="userDetails.followed_countries.includes(scope.row.name)"
                  @click="triggerCountryFollow(scope.row.name)"
                />
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import StatusComponent from "@/buying-teams/shared-components/utils/StatusComponent";
import store from "@/store";
import { getStatusClass } from "@/core/helpers/GlobalHelper";
import FollowingButton from "@/buying-teams/shared-components/utils/FollowingButton";
import { BankUserSettingsContainer } from "@/store/models/bank/BankUserSettingsContainer";
import BankFollowType from "@/store/enums/bank/BankFollowType";
import BankFollowOperationEnum from "@/store/enums/bank/BankFollowOperationEnum";
import { BankFilterService } from "@/core/services/BankFilterService";

export default {
  name: "FollowedCountries",
  components: {FollowingButton, StatusComponent},
  props: {
    userDetails: BankUserSettingsContainer
  },
  emits: ['onCountryFollow'],
  data() {
    return {
      getStatusClass,
      value: [],
      loading: {}
    }
  },
  computed: {
    countries() {
      return store.getters.allCountries;
    },
  },
    methods: {
      async triggerCountryFollow(country) {
        this.loading[country] = true;

        const operation = this.userDetails.followed_countries.includes(country)
          ? BankFollowOperationEnum.REMOVE
          : BankFollowOperationEnum.ADD;

        await store.dispatch("updateBankUserFollows", {
          type: BankFollowType.COUNTRY,
          operation,
          items: [country]
        }).then( async () => {
          await store.dispatch('reInitFiltersData');

          this.userDetails.addRemoveCountry(operation, country);
        }).finally(() => {
          this.loading[country] = false;
        });
      }
    }
}
</script>
